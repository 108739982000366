export const SvgZijrruit = () => {

    return (
        <svg className="svg-side-window" width="70px" height="32px" viewBox="0 0 66 32" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <defs>
                <polygon id="path-xrvd5_2ojg-1"
                         points="0 6.9388939e-16 30.8736824 6.9388939e-16 30.8736824 30.0548266 0 30.0548266"></polygon>
                <polygon id="path-xrvd5_2ojg-3"
                         points="0 6.9388939e-16 30.8736824 6.9388939e-16 30.8736824 30.0548266 0 30.0548266"></polygon>
                <polygon id="path-xrvd5_2ojg-5"
                         points="3.33066907e-16 6.9388939e-16 30.8736455 6.9388939e-16 30.8736455 30.0548319 3.33066907e-16 30.0548319"></polygon>
                <polygon id="path-xrvd5_2ojg-7"
                         points="3.33066907e-16 6.9388939e-16 30.8736455 6.9388939e-16 30.8736455 30.0548319 3.33066907e-16 30.0548319"></polygon>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="211118-ABS-platform-[ruitschade-2]" transform="translate(-733.000000, -872.000000)">
                    <g id="zijruit" transform="translate(733.000000, 872.000000)">
                        <g id="Group-3" transform="translate(0.000000, 0.052691)">
                            <g id="Fill-1-Clipped">
                                <mask id="mask-xrvd5_2ojg-2" fill="white">
                                    <use href="#path-xrvd5_2ojg-1"></use>
                                </mask>
                                <g id="path-1"></g>
                                <path
                                    d="M27.1933657,12.0680748 C27.1933657,12.6439804 26.7265291,13.1108171 26.1506234,13.1108171 L5.65696933,13.1108171 C4.72793281,13.1108171 4.26267689,11.9876166 4.91962036,11.3307785 L12.2647627,3.98563622 C12.4602439,3.79015496 12.7253818,3.68029555 13.0020062,3.68029555 L26.1506234,3.68029555 C26.7265291,3.68029555 27.1933657,4.14713218 27.1933657,4.72303788 L27.1933657,12.0680748 Z M26.1506234,18.2258397 L22.59297,18.2258397 C22.0172223,18.2258397 21.5502276,17.759003 21.5502276,17.18315 C21.5502276,16.6071916 22.0172223,16.1404077 22.59297,16.1404077 L26.1506234,16.1404077 C26.7265291,16.1404077 27.1933657,16.6071916 27.1933657,17.18315 C27.1933657,17.759003 26.7265291,18.2258397 26.1506234,18.2258397 L26.1506234,18.2258397 Z M29.83094,-2.10762e-05 L11.477674,-2.10762e-05 C11.2010496,-2.10762e-05 10.9357536,0.109838336 10.7402723,0.30531959 L0.305472392,10.7401195 C0.109991137,10.9356007 -2.63451826e-05,11.2008967 -2.63451826e-05,11.4774158 L-2.63451826e-05,17.467678 C-2.63451826e-05,18.3159929 0.624670625,19.039695 1.46560885,19.1516621 C6.36844733,19.8043376 10.2506208,23.6865637 10.903349,28.5894022 C11.015316,29.4303404 11.7390182,30.0548266 12.5873331,30.0548266 L29.83094,30.0548266 C30.4067931,30.0548266 30.8736824,29.58799 30.8736824,29.012137 L30.8736824,1.04272125 C30.8736824,0.46681556 30.4067931,-2.10762e-05 29.83094,-2.10762e-05 L29.83094,-2.10762e-05 Z"
                                    id="Fill-1" fill="#E67A01" className="fill-primary"fillRule="nonzero"
                                    mask="url(#mask-xrvd5_2ojg-2)"></path>
                            </g>
                            <g id="Fill-1-Clipped">
                                <mask id="mask-xrvd5_2ojg-4" fill="white">
                                    <use href="#path-xrvd5_2ojg-3"></use>
                                </mask>
                                <g id="path-3"></g>
                                <path
                                    d="M27.1933657,12.0680748 C27.1933657,12.6439804 26.7265291,13.1108171 26.1506234,13.1108171 L5.65696933,13.1108171 C4.72793281,13.1108171 4.26267689,11.9876166 4.91962036,11.3307785 L12.2647627,3.98563622 C12.4602439,3.79015496 12.7253818,3.68029555 13.0020062,3.68029555 L26.1506234,3.68029555 C26.7265291,3.68029555 27.1933657,4.14713218 27.1933657,4.72303788 L27.1933657,12.0680748 Z"
                                    id="Fill-1" fill="#E67A01" className="fill-secondary" fillRule="nonzero"
                                    mask="url(#mask-xrvd5_2ojg-4)"></path>
                            </g>
                        </g>
                        <g id="Group-6" transform="translate(34.554457, 0.052691)">
                            <g id="Fill-4-Clipped">
                                <mask id="mask-xrvd5_2ojg-6" fill="white">
                                    <use href="#path-xrvd5_2ojg-5"></use>
                                </mask>
                                <g id="path-5"></g>
                                <path
                                    d="M3.68031663,4.72304315 C3.68031663,4.14713745 4.14710057,3.68030082 4.72305896,3.68030082 L17.8716761,3.68030082 C18.1483006,3.68030082 18.4134385,3.79016023 18.6089197,3.98564148 L25.954062,11.3307837 C26.6110055,11.9876218 26.1457496,13.1108223 25.2166604,13.1108223 L4.72305896,13.1108223 C4.14710057,13.1108223 3.68031663,12.6439857 3.68031663,12.06808 L3.68031663,4.72304315 Z M3.68031663,17.1831553 C3.68031663,16.6071969 4.14710057,16.1403603 4.72305896,16.1403603 L8.28071241,16.1403603 C8.85646003,16.1403603 9.32345474,16.6071969 9.32345474,17.1831553 C9.32345474,17.7590083 8.85646003,18.2258449 8.28071241,18.2258449 L4.72305896,18.2258449 C4.14710057,18.2258449 3.68031663,17.7590083 3.68031663,17.1831553 L3.68031663,17.1831553 Z M3.33066907e-16,29.0121423 C3.33066907e-16,29.5879953 0.466836636,30.0548319 1.04274233,30.0548319 L18.2863493,30.0548319 C19.1346642,30.0548319 19.8583664,29.4303457 19.9703334,28.5894075 C20.6230089,23.686569 24.5052351,19.8043429 29.4080735,19.1516146 C30.2490118,19.0396476 30.8737087,18.3159981 30.8737087,17.4676306 L30.8737087,11.477421 C30.8737087,11.200902 30.7636913,10.935606 30.56821,10.7401247 L20.1334101,0.305324859 C19.9379288,0.109843605 19.6726328,-1.58071e-05 19.3960084,-1.58071e-05 L1.04274233,-1.58071e-05 C0.466836636,-1.58071e-05 3.33066907e-16,0.466820829 3.33066907e-16,1.04272652 L3.33066907e-16,29.0121423 Z"
                                    id="Fill-4" fill="#E67A01" className="fill-primary"fillRule="nonzero"
                                    mask="url(#mask-xrvd5_2ojg-6)"></path>
                            </g>
                            <g id="Fill-4-Clipped">
                                <mask id="mask-xrvd5_2ojg-8" fill="white">
                                    <use href="#path-xrvd5_2ojg-7"></use>
                                </mask>
                                <g id="path-7"></g>
                                <path
                                    d="M3.68031663,4.72304315 C3.68031663,4.14713745 4.14710057,3.68030082 4.72305896,3.68030082 L17.8716761,3.68030082 C18.1483006,3.68030082 18.4134385,3.79016023 18.6089197,3.98564148 L25.954062,11.3307837 C26.6110055,11.9876218 26.1457496,13.1108223 25.2166604,13.1108223 L4.72305896,13.1108223 C4.14710057,13.1108223 3.68031663,12.6439857 3.68031663,12.06808 L3.68031663,4.72304315 Z"
                                    id="Fill-4" fill="#E67A01" className="fill-secondary" fillRule="nonzero"
                                    mask="url(#mask-xrvd5_2ojg-8)"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}