import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface AppState {
    activeHelpType?: string,
    generalStep: number,
    glassReplacement: boolean,
}

const initialState: AppState = {
    generalStep: 0,
    glassReplacement: false
}

const helpTypeSlice = createSlice({
    name: 'helpType',
    initialState,
    reducers: {
        setHelpType: (state, action: PayloadAction<string>) => {
            state.activeHelpType = action.payload
        },
        deleteHelpType: (state) => {
            delete state.activeHelpType
        },
        setGeneralStep: (state, action: PayloadAction<number>) => {
            state.generalStep = action.payload
        },
        setGlassReplacement: (state, action: PayloadAction<boolean>) => {
            state.glassReplacement = action.payload
        }
    }
})

export const {setHelpType, deleteHelpType, setGeneralStep, setGlassReplacement} = helpTypeSlice.actions
export const helpTypeReducer = helpTypeSlice.reducer
