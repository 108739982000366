import axios from "axios";
import {ApiEnum} from "../../constants/ApiEnum";

export const ApiGetCoordsByAddress = async (address: string) => {

    return await axios.get(ApiEnum.GEOCODE + '?address=' + address).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}