import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AgendaMomentInterface} from "../../common/interfaces/AgendaMomentInterface";

interface AppointmentTimeInterface {
    startTime: string,
    endTime: string
}

interface AppointmentInterface {
    userLocation: {
        input: string,
        lat?: number,
        lng?: number,
        geoLocationActive: boolean
    },
    agendaMoments: {
        count?: number,
        limit: number,
        activeAgendaMoment?: AgendaMomentInterface,
        fast: number,
        loading: boolean
    },
    selectedDate?: string,
    selectedTime?: AppointmentTimeInterface,
    loanType: string
}


const initialState: AppointmentInterface = {
    userLocation: {
        input: '',
        geoLocationActive: false
    },
    agendaMoments: {
        limit: 3,
        activeAgendaMoment: undefined,
        fast: 0,
        loading: false
    },
    loanType: ''
}

interface Coords {
    lat: number,
    lng: number
}

const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        setUserLocationInput: (state, action: PayloadAction<string>) => {
            state.userLocation.input = action.payload
            state.userLocation.geoLocationActive = false
            state.loanType = ''
        },
        setCoords: (state, action: PayloadAction<Coords>) => {
            state.userLocation.lat = action.payload.lat
            state.userLocation.lng = action.payload.lng
        },
        setGeolocation: (state, action: PayloadAction<boolean>) => {
            state.userLocation.input = ''
            state.userLocation.geoLocationActive = action.payload
        },
        toggleSortFast: (state) => {
            state.agendaMoments.fast = !!state.agendaMoments.fast ? 0 : 1
            state.loanType = ''
        },
        setDate: (state, action: PayloadAction<string>) => {
            state.selectedDate = action.payload
            state.selectedTime = {
                startTime: '',
                endTime: ''
            }
            state.loanType = ''
        },
        setTime: (state, action: PayloadAction<AppointmentTimeInterface>) => {
            state.selectedTime = {
                ...action.payload
            }
            state.loanType = ''
        },
        increaseAgendaMomentLimit: (state) => {
            if (state.agendaMoments && state.agendaMoments.limit) {
                state.agendaMoments.limit = state.agendaMoments.limit + 3
            }
        },
        setActiveAgendaMoment: (state, action: PayloadAction<AgendaMomentInterface>) => {
            state.loanType = ''

            if (state.agendaMoments) {
                if (state.agendaMoments.activeAgendaMoment) {
                    state.agendaMoments.activeAgendaMoment = undefined
                    state.selectedDate = undefined
                } else {
                    state.agendaMoments.activeAgendaMoment = action.payload

                }
            }
        },
        unsetAppointment: (state) => {
            state.agendaMoments.activeAgendaMoment = undefined
            state.loanType = ''
        },
        setLoanType: (state, action: PayloadAction<string>) => {
            state.loanType = action.payload
        },
    },
})

export const {
    setUserLocationInput,
    setCoords,
    setGeolocation,
    setActiveAgendaMoment,
    unsetAppointment,
    increaseAgendaMomentLimit,
    setDate,
    setTime,
    setLoanType,
    toggleSortFast
} = appointmentSlice.actions
export const appointmentReducer = appointmentSlice.reducer
