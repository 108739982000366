import {Header} from "../common/components/general/Header";
import {useAppDispatch, useAppSelector} from "./hooks";
import {geolocated} from "react-geolocated";
import HelpModal from "../common/components/modals/Help";
import GreenCardModal from "../common/components/modals/GreenCard";
import {BeatLoader, GridLoader} from "react-spinners";
import {css} from "@emotion/react";
import {useEffect, useRef, useState} from "react";
import {getDossier, getRegistrationLink, setGetParams, unsetDossier} from "./rootReducer";
import {Helmet} from "react-helmet";
import {setFundingType} from "../features/vehicleInformation/vehicleInformationSlice";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import moment from "moment";
import {EmailNotification} from "../features/emailNotification/EmailNotification";
import VehicleInformationLicenseChecker
    from "../features/vehicleInformation/components/VehicleInformationLicenseChecker";
import {HelpTypeSelect} from "../features/helpType/HelpType";
import VehicleInformationBrandModel from "../features/vehicleInformation/components/VehicleInformationBrandModel";
import VehicleInformationDamageCause from "../features/vehicleInformation/components/VehicleInformationDamageCause";
import VehicleInformationLease from "../features/vehicleInformation/components/VehicleInformationLease";
import VehicleInformationInsurance from "../features/vehicleInformation/components/VehicleInformationInsurance";
import VehicleInformationGlassDamage from "../features/vehicleInformation/components/VehicleInformationGlassDamage";
import Form from "../features/form/Form";
import AppointmentEstablishments from "../features/appointment/components/AppointmentEstablishments";
import AppointmentDayPicker from "../features/appointment/components/AppointmentDayPicker";
import AppointmentTimePicker from "../features/appointment/components/AppointmentTimePicker";
import AppointmentLoanVehicle from "../features/appointment/components/AppointmentLoanVehicle";
import OverviewVehicleInformation from "../features/overview/components/OverviewVehicleInformation";
import OverviewForm from "../features/overview/components/OverviewForm";
import OverviewAppointment from "../features/overview/components/OverviewAppointment";
import Result from "../features/result/Result";
import {SubmitButton} from "../features/overview/components/SubmitButton";
import AppointmentUserLocation from "../features/appointment/components/AppointmentUserLocation";
import {GeneralStepsEnum} from "../common/constants/GeneralStepsEnum";
import ImageCaptureInfo from "../features/overview/components/ImageCaptureInfo";
import SmartPhotoModal from "../common/components/modals/SmartPhoto";
import {MobileCheckModal} from "../common/components/modals/MobileCheck";
import {fillFormByDossier} from "../common/helpers/fillFormByDossier";
import {useLazyGetAgendaMomentsQuery} from "../features/apiData/apiDataSlice";
import {dossierTypeEnum} from "../types/dossierTypeEnum";
import OverviewRepairReplace from "../features/overview/components/OverviewRepairReplace";
import {fundingTypeEnum} from "../types/fundingTypeEnum";

const override = css`
    position: fixed;
    left: 1rem;
    bottom: 1rem;
`;

function App() {

    const {root, helpType} = useAppSelector(state => state);
    const {agendaMoments, userLocation} = useAppSelector((state) => state.appointment)
    const {fundingType, lease, insurance, missingContractorName} = useAppSelector((state) => state.vehicleInformation)

    const [agendaMomentTrigger] = useLazyGetAgendaMomentsQuery()

    const {
        activeHelpType,
        generalStep,
        glassReplacement
    } = helpType;

    const isGeneralDamage = activeHelpType === dossierTypeEnum.BodyRepair
    const isCaravanDamage = activeHelpType === dossierTypeEnum.CaravanRepair
    const isGlassDamage = (activeHelpType === dossierTypeEnum.GlassReplacement || activeHelpType === dossierTypeEnum.GlassRepair)

    const {
        dossier,
        modals,
        finished,
        dossierLoading,
        dossierError,
        registrationLink,
        getParams,
        expiresAt
    } = root

    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams();
    const [fillFormLoader, setFillFormLoader] = useState(false)

    const navigate = useNavigate()

    let {slug} = useParams();
    const initialMount = useRef(true);

    const establishmentId = searchParams.get("establishment-asp-id")
    const forceEstablishment = searchParams.get("establishment-asp-force")
    const dossierUuid = searchParams.get("uuid")
    const dossierAccessToken = searchParams.get("access_token")
    const getParamHelpType = searchParams.get("help-type")

    const localDossierUuidKey = slug + '-dossier_uuid';
    const localDossierAccessTokenKey = slug + '-dossier_access_token';

    const resultUrl = registrationLink?.thank_you_page_url || ''

    useEffect(() => {
        if (!slug) {
            navigate("/abs-autoherstel");
        } else {
            dispatch(getRegistrationLink(slug + ''))

            if (moment(expiresAt).isBefore(moment())) {
                window.location.reload()
            }
            if (slug === 'athlon') {
                dispatch(setFundingType(fundingTypeEnum.Lease))
            }

            if (slug === 'unigarant') {
                dispatch(setFundingType(fundingTypeEnum.Particulier))
            }
            /** Set get params in store **/
            if (establishmentId) {
                dispatch(setGetParams({
                    ...getParams,
                    establishmentId: parseInt(establishmentId),
                    forceEstablishment: !!forceEstablishment
                }))
            }
        }

        if (getParamHelpType) {
            localStorage.removeItem(localDossierUuidKey)
            localStorage.removeItem(localDossierAccessTokenKey)
            dispatch(unsetDossier())
        }

    }, [slug]);


    useEffect(() => {
        if (dossier && dossier.dossier_uuid && dossier.dossier_access_token && !localStorage.getItem(localDossierUuidKey) && !localStorage.getItem(localDossierAccessTokenKey)) {
            localStorage.setItem(localDossierUuidKey, dossier.dossier_uuid + '')
            localStorage.setItem(localDossierAccessTokenKey, dossier.dossier_access_token + '')
        }

        if (dossier && dossier.dossier_uuid && dossier.dossier_access_token && localStorage.getItem(localDossierUuidKey) && localStorage.getItem(localDossierAccessTokenKey)) {
            if (dossier.dossier_uuid !== parseInt(localStorage.getItem(localDossierUuidKey) as string) || dossier.dossier_access_token !== localStorage.getItem(localDossierAccessTokenKey)) {
                localStorage.setItem(localDossierUuidKey, dossier.dossier_uuid + '')
                localStorage.setItem(localDossierAccessTokenKey, dossier.dossier_access_token + '')
            }
        }
    }, [dossier]);


    useEffect(() => {

        if (dossierUuid) localStorage.setItem(localDossierUuidKey, dossierUuid + '')
        if (dossierAccessToken) localStorage.setItem(localDossierAccessTokenKey, dossierAccessToken + '')

        if (initialMount.current && localStorage.getItem(localDossierUuidKey) && localStorage.getItem(localDossierAccessTokenKey)) {
            setFillFormLoader(true)
            dispatch(getDossier({
                dossierUuid: localStorage.getItem(localDossierUuidKey) + '',
                dossierAccessToken: localStorage.getItem(localDossierAccessTokenKey) + '',
            })).then(async (result: { payload: any | boolean }) => {
                if (result.payload === false) {
                    localStorage.removeItem(localDossierUuidKey)
                    localStorage.removeItem(localDossierAccessTokenKey)
                    setFillFormLoader(false)
                    return
                }
                await fillFormByDossier(result, dispatch, agendaMoments, agendaMomentTrigger)
                setFillFormLoader(false)
            })
            initialMount.current = false
        }
    }, []);

    useEffect(() => {
        if (resultUrl) {
            localStorage.removeItem(localDossierUuidKey)
            localStorage.removeItem(localDossierAccessTokenKey)
            dispatch(unsetDossier())
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

            if (activeHelpType === dossierTypeEnum.GlassReplacement || activeHelpType === dossierTypeEnum.GlassRepair) {
                window.dataLayer.push({
                    event: "RuitschadeAanmeldenBedankt",
                })
            }

            if (activeHelpType === dossierTypeEnum.BodyRepair) {
                window.dataLayer.push({
                    event: "AutoschadeAanmeldenBedankt",
                });
            }

            if (activeHelpType === dossierTypeEnum.CaravanRepair) {
                window.dataLayer.push({
                    event: "CamperschadeAanmeldenBedankt",
                });
            }

            window.location.href = resultUrl;
        }
    }, finished);

    const AppointmentCondition = (lease.activeContractorId || insurance.activeContractorId || fundingType === fundingTypeEnum.Self) || glassReplacement || isCaravanDamage

    if (registrationLink && !registrationLink.loading && !registrationLink.notFound && !fillFormLoader) {

        return (
            <>

                <Helmet>
                    <style type="text/css">{`
                        .background-application {
                            background-color: #F3F4F6;
                        }
                        .border-color-primary {
                            border-color: #F3F4F6;
                        }
                        .c-locations__single.isActive .border-color-primary {
                            border-color: ` + registrationLink.primary_color + `;   
                            background-color: ` + registrationLink.background_light + `; 
                            box-shadow: none;
                        }
                        .c-locations__single.isActive .border-color-primary::after {
                            border-color: ` + registrationLink.primary_color + `;   
                            background-color: ` + registrationLink.background_light + `; 
                            box-shadow: none;
                        }
                        .border-color-primary-hover:hover {   
                            border-color: ` + registrationLink.primary_color + `;   
                            background-color: ` + registrationLink.background_light + `; 
                            box-shadow: none;
                        }
                        .border-color-primary-hover:hover::after {
                            border-color: ` + registrationLink.primary_color + `;   
                            box-shadow: none;
                            background-color: ` + registrationLink.background_light + `; 
                        }
                        .border-color-primary-hover.button-active::after{
                            border-color: ` + registrationLink.primary_color + `;   
                            box-shadow: none;
                            background-color: ` + registrationLink.background_light + `; 
                        }
                        .color-primary, .icon-check-circle:before {
                            color: ` + registrationLink.primary_color + `;
                        }
                        .color-primary-before:before {
                            color: ` + registrationLink.primary_color + `;
                        }
                        .color-secondary-before:before {
                            color: ` + registrationLink.secondary_color + `;
                        }
                        
                        .fill-primary: {
                            color: ` + registrationLink.primary_color + `;
                        }
                        
                        .fill-secondary: {
                            color: ` + registrationLink.secondary_color + `;
                        }
                        
                        .color-secondary {
                            color: ` + registrationLink.primary_color + `;
                        }
                        .background-primary {
                            background-color: ` + registrationLink.primary_color + `;
                        }
                        .background-secondary {
                            background-color: ` + registrationLink.secondary_color + `;
                        }
                        /*Different from secondary*/
                        .background-cta,  .DayPicker-NavButton {
                            background-color: ` + registrationLink.primary_color + `;
                        }
                        .background-light {
                            background-color: ` + registrationLink.background_light + `;
                        }
                      
                        .button-background-color-light {
                            background-color: #fff;
                        }
                        .button-active {
                            border-color: ` + registrationLink.primary_color + `;
                            background-color: ` + registrationLink.background_light + `!important;
                        }
                        
                        .stepActive div, .c-switch__header__help .btn {
                            background-color: ` + registrationLink.primary_color + `;
                        }
                        
                        .color-dark {
                            /*Different from color-primary*/
                            color: ` + registrationLink.secondary_color + `;
                        }
                        .fill-primary {
                            fill: ` + registrationLink.secondary_color + `;
                        }
                        .fill-secondary {
                            fill: ` + registrationLink.primary_color + `;
                        }
                        .font-face-primary {
                            font-family: 'Roboto Slab', serif;
                        }
                        .font-face-primary-bold {
                            font-family: 'Roboto Slab', serif;
                            font-weight: bold;
                        }
                        .button-active .s-appointment__options__single__icon:before {
                        color: ` + registrationLink.primary_color + `;
                        }
                        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before {
                            background: ` + registrationLink.primary_color + `;
                        }
                        .c-form__group input[type=checkbox]:checked {
                            background-color: ` + registrationLink.primary_color + `;
                            border-color: ` + registrationLink.primary_color + `;
                        }                                
                    `}</style>

                </Helmet>

                <Header/>

                {/** Modals */}
                {modals.help && <HelpModal/>}
                {modals.greenCard && <GreenCardModal/>}
                {modals.smartPhoto && <SmartPhotoModal/>}
                {modals.mobileNumberCheck && <MobileCheckModal/>}

                {!finished &&
                    <div className="o-container o-container--primary">
                        <div className="s-appointment">
                            <div className="c-form">

                                <EmailNotification/>

                                <VehicleInformationLicenseChecker/>
                                <VehicleInformationBrandModel/>

                                {generalStep >= GeneralStepsEnum.TypeSelect &&
                                    <HelpTypeSelect/>
                                }

                                {isGeneralDamage && generalStep >= GeneralStepsEnum.DamageCause &&
                                    <VehicleInformationDamageCause/>
                                }

                                {isGlassDamage && generalStep >= GeneralStepsEnum.GlassDamage &&
                                    <VehicleInformationGlassDamage/>
                                }

                                {generalStep >= GeneralStepsEnum.Lease && !glassReplacement && !isCaravanDamage &&
                                    <VehicleInformationLease/>
                                }

                                {generalStep >= GeneralStepsEnum.Insurance && fundingType === fundingTypeEnum.Particulier && !!dossier?.dossier_id && !glassReplacement && !isCaravanDamage &&
                                    <VehicleInformationInsurance/>
                                }

                                {generalStep >= GeneralStepsEnum.Appointment && (!!AppointmentCondition || missingContractorName) &&
                                    <>
                                        <AppointmentUserLocation/>
                                        {!!userLocation?.lat && !!userLocation?.lng &&
                                            <>
                                                <AppointmentEstablishments/>
                                                {(!isCaravanDamage && !glassReplacement && fundingType !== fundingTypeEnum.Self) && !missingContractorName &&
                                                    <>
                                                        <AppointmentDayPicker/>
                                                        <AppointmentTimePicker/>
                                                        <AppointmentLoanVehicle/>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }

                                {!glassReplacement && generalStep >= GeneralStepsEnum.Form && fundingType !== fundingTypeEnum.Self &&
                                    <Form/>
                                }

                                {!glassReplacement && generalStep >= GeneralStepsEnum.EnableResume &&
                                    <>
                                        <OverviewVehicleInformation/>
                                        <OverviewRepairReplace/>
                                        <OverviewAppointment/>
                                        <OverviewForm/>
                                        <ImageCaptureInfo/>
                                        <SubmitButton/>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                }

                {finished && generalStep >= GeneralStepsEnum.Finished && !resultUrl &&
                    <Result/>
                }

                {dossierError.show &&
                    <p className={'c-error'}>{dossierError.message}</p>
                }

                <BeatLoader css={override} loading={dossierLoading} color={registrationLink.primary_color}/>

            </>
        );

    } else if (registrationLink.loading || fillFormLoader) {
        return <div className={'c-not-found'} style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}>
            <GridLoader color={registrationLink.primary_color} loading={registrationLink.loading || fillFormLoader}/>
        </div>
    } else if (registrationLink.notFound) {
        return <div className={'c-not-found'} style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}><p>Aanmeldlink onbekend</p></div>
    } else return null
}

export default geolocated()(App);
