export const SvgCamper = () => {

    return (
        <svg width="90" height="92" viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: '-2rem', marginTop: '-2rem'}}>
            <title>Artboard</title>
            <defs>
                <polygon id="path-1" points="3.14573913e-05 0.0348693429 117.37138 0.0348693429 117.37138 61.7302737 3.14573913e-05 61.7302737"></polygon>
                <polygon id="path-3" points="0.0182194706 0.0405881057 42.9077032 0.0405881057 42.9077032 45.0620529 0.0182194706 45.0620529"></polygon>
            </defs>
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-16" transform="translate(2.657895, 36.000000)">
                    <g id="Group-8" transform="translate(0.342105, 4.000000)">
                        <path d="M97.1107664,66.3865752 C94.5211768,66.3865752 92.4056931,64.28759 92.4056931,61.7192083 C92.4056931,59.1361778 94.5211768,57.0419962 97.1107664,57.0419962 C99.6918185,57.0419962 101.806664,59.1361778 101.806664,61.7192083 C101.806664,64.28759 99.6918185,66.3865752 97.1107664,66.3865752 M97.1163117,51.4285714 C91.4154726,51.4285714 86.7583524,56.0387723 86.7583524,61.7251234 C86.7583524,67.379279 91.4154726,72 97.1163117,72 C102.798321,72 107.454005,67.379279 107.454005,61.7251234 C107.454005,56.0387723 102.798321,51.4285714 97.1163117,51.4285714" id="Fill-1" fill="#1D2D58" className={"fill-primary"}></path>
                        <g id="Group-5" transform="translate(0.871396, 0.000000)">
                            <mask id="mask-2" fill="white">
                                <use href="#path-1"></use>
                            </mask>
                            <g id="Clip-4"></g>
                            <path d="M80.2909726,39.5490659 L80.2909726,23.9649558 L91.3185578,23.9649558 L102.101483,39.5490659 L80.2909726,39.5490659 Z M62.2521641,54.3693209 C62.2521641,55.4606937 61.3775307,56.3463483 60.249272,56.3463483 L48.1727008,56.3463483 C47.0902913,56.3463483 46.1788134,55.4606937 46.1788134,54.3693209 L46.1788134,18.6078923 C46.1788134,17.5180096 47.0902913,16.6189437 48.1727008,16.6189437 L60.249272,16.6189437 C61.3775307,16.6189437 62.2521641,17.5180096 62.2521641,18.6078923 L62.2521641,54.3693209 Z M33.6571988,16.6189437 C34.7600556,16.6189437 35.6346891,17.5180096 35.6346891,18.6078923 L35.6346891,30.172342 C35.6346891,31.2756751 34.7600556,32.1578397 33.6571988,32.1578397 L17.6955612,32.1578397 C16.6110676,32.1578397 15.7285305,31.2756751 15.7285305,30.172342 L15.7285305,18.6078923 C15.7285305,17.5180096 16.6110676,16.6189437 17.6955612,16.6189437 L33.6571988,16.6189437 Z M106.663316,39.6976881 L92.4757179,20.5998211 L79.8170277,20.5998211 L79.8170277,18.1253213 C79.8170277,18.1253213 91.3185578,17.8355277 102.812066,18.1253213 C112.236386,18.3613128 112.236386,10.3021889 107.018155,7.73671191 C101.791942,5.19460663 91.7650561,0.0348693429 91.7650561,0.0348693429 L40.3231773,0.0348693429 L30.2708893,5.16574491 L5.14701118,5.16574491 C0.228294153,5.16574491 3.14573917e-05,6.10210363 3.14573917e-05,10.3156002 L3.14573917e-05,55.8771886 L9.64459238,61.7302737 L15.4997173,61.7302737 C15.4997173,54.7067598 21.218907,48.9933561 28.2580488,48.9933561 C35.3062346,48.9933561 41.0248344,54.7067598 41.0248344,61.7302737 L83.7057511,61.7302737 C83.7057511,54.7067598 89.426317,48.9933561 96.4725367,48.9933561 C103.495281,48.9933561 109.249231,54.7067598 109.249231,61.7302737 L117.371412,61.7302737 L117.371412,46.3235298 C116.972296,46.0432261 106.663316,39.6976881 106.663316,39.6976881 L106.663316,39.6976881 Z" id="Fill-3" fill="#1D2D58" className={"fill-primary"} mask="url(#mask-2)"></path>
                        </g>
                        <path d="M29.8498968,66.3865752 C27.2603072,66.3865752 25.1448235,64.28759 25.1448235,61.7192083 C25.1448235,59.1361778 27.2603072,57.0419962 29.8498968,57.0419962 C32.4309489,57.0419962 34.5457944,59.1361778 34.5457944,61.7192083 C34.5457944,64.28759 32.4309489,66.3865752 29.8498968,66.3865752 M29.8554421,51.4285714 C24.1546429,51.4285714 19.4974828,56.0387723 19.4974828,61.7251234 C19.4974828,67.379279 24.1546429,72 29.8554421,72 C35.5374511,72 40.193135,67.379279 40.193135,61.7251234 C40.193135,56.0387723 35.5374511,51.4285714 29.8554421,51.4285714" id="Fill-6" fill="#1D2D58" className={"fill-primary"}></path>
                    </g>
                    <g id="Group-6" transform="translate(133.087384, 32.025366) scale(-1, -1) rotate(-137.000000) translate(-133.087384, -32.025366) translate(111.587384, 9.025366)">
                        <mask id="mask-4" fill="white">
                            <use href="#path-3"></use>
                        </mask>
                        <g id="Clip-5"></g>
                        <polygon id="Fill-4" fill="#E67A01" className={"fill-secondary"} mask="url(#mask-4)" points="37.8404129 45.0620529 34.6767603 44.1253632 37.7446947 33.8499584 22.7972611 40.1831818 25.2259875 26.5800453 17.3341017 32.3426526 9.62854684 24.1148625 8.7128182 36.9201528 5.44618594 37.3908187 5.31580917 16.0114598 18.1876443 27.6440042 29.91779 19.0785993 27.1138646 34.7826221 42.9077032 28.0905966"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )

}
