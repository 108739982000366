import {configureStore, combineReducers} from '@reduxjs/toolkit'
import rootReducer from "./rootReducer";
import {helpTypeReducer} from "../features/helpType/helpTypeSlice";
import {vehicleInformationReducer} from "../features/vehicleInformation/vehicleInformationSlice";
import {formReducer} from "../features/form/FormSlice";
import {appointmentReducer} from "../features/appointment/appointmentSlice";
import {apiDataSlice} from "../features/apiData/apiDataSlice";

let slug = (window.location.pathname + ':').replaceAll('/', '');
if (slug.length < 2) {
  slug = 'abs-autoherstel:';
}

const reducer = combineReducers({
    [apiDataSlice.reducerPath]: apiDataSlice.reducer,
    root: rootReducer,
    helpType: helpTypeReducer,
    vehicleInformation: vehicleInformationReducer,
    form: formReducer,
    appointment: appointmentReducer
});

export const store: any = configureStore({
    reducer: reducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
