import {createAndUpdateArgs} from "../../interfaces/DossierInterface";
import axios from "axios";
import {ApiEnum} from "../../constants/ApiEnum";
import {disableApiError} from "../../../app/rootReducer";


export const ApiDossierCreateAndUpdate = async (slug: string, dispatch: any, args?: createAndUpdateArgs) => {

    return await axios.post(ApiEnum.DOSSIER + '/create-and-update', {
        data: {
            patchData: args?.data,
            registration_link: slug
        }
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then(function (response) {
        dispatch(disableApiError())
        return response
    }).catch(function (error) {
        return error
    })

}

