import React, { useState } from 'react';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { enableGreenCardModal } from '../../../app/rootReducer';
import { css } from '@emotion/react';
import MoonLoader from 'react-spinners/MoonLoader';
import SvgNederland from "../svgIcons/SvgNederland.svg";
import SvgBelgie from "../svgIcons/SvgBelgie.svg";

const override = css`
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 1.3rem;
`;

interface CountryOption {
    value: string;
    label: string;
    flag: string;
}

interface InputProps {
    label?: string;
    name?: string;
    required?: boolean;
    onChange: (combinedValue: string) => void;
    placeholder?: string;
    value?: string;
    modal?: string;
    type: string;
    loading?: boolean;
    pattern?: string;
    message?: string;
    maxLength?: number;
    prepend?: string;
    maxDate?: string;
    minDate?: string;
    error?: string | false;
}

const FormInputPhoneNumber = (props: InputProps) => {
    const [countryCode, setCountryCode] = useState<CountryOption>({ value: '+31', label: '+31', flag: SvgNederland });
    const [phoneNumber, setPhoneNumber] = useState(props.value || '');
    const [placeholder, setPlaceholder] = useState('0612345678');
    const dispatch = useAppDispatch();
    const { root } = useAppSelector((state) => state);
    const { registrationLink } = root;

    const countryOptions: CountryOption[] = [
        { value: '+31', label: '+31', flag: SvgNederland },
        { value: '+32', label: '+32', flag: SvgBelgie },
    ];

    const formatOptionLabel = ({ label, flag }: CountryOption) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={flag} alt="flag" style={{ width: 20, height: 15, marginRight: 10 }} />
            {label}
        </div>
    );

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedPhoneNumber = e.target.value;
        setPhoneNumber(updatedPhoneNumber);
        props.onChange(countryCode.value + updatedPhoneNumber);
    };

    const handleCountryCodeChange = (selectedOption: CountryOption) => {
        setCountryCode(selectedOption);
        props.onChange(selectedOption.value + phoneNumber);

        setCountryCode(selectedOption);
        let placeholderText = '';
        if (selectedOption.value === '+31') {
            placeholderText = '0612345678';
        } else if (selectedOption.value === '+32') {
            placeholderText = '0412345678';
        }
        setPlaceholder(placeholderText);
    };

    return (
        <div className="c-form__group" style={{ position: 'relative' }}>
            <label htmlFor="">
                {props.label} {props.required && <span>*</span>}{' '}
                {props.modal && (
                    <button onClick={() => dispatch(enableGreenCardModal())} type="button">
                        Waar vind ik dit?
                    </button>
                )}
            </label>

            {props.loading && (
                <MoonLoader
                    color={registrationLink.secondary_color}
                    loading={props.loading}
                    css={override}
                    size={15}
                />
            )}

            <div className={'c-input__group c-input__group--phone-number'}>
                <Select
                    value={countryCode}
                    onChange={(selectedOption) => handleCountryCodeChange(selectedOption as CountryOption)}
                    options={countryOptions}
                    formatOptionLabel={formatOptionLabel}
                    className={'c-input__group--phone-number--prefix'}
                />

                <input
                    className={'border-color-primary c-input__group--phone-number--suffix'}
                    disabled={props.loading}
                    name={props.name}
                    required={props.required}
                    type={props.type}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder={placeholder}
                    pattern={props.pattern}
                    title={props.message}
                    maxLength={props.maxLength}
                    max={props.maxDate}
                    min={props.minDate}
                />

                {!!props.error && <p className="c-form__error">{props.error}</p>}
            </div>
        </div>
    );
};

export default FormInputPhoneNumber;