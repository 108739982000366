import React from 'react';
import ReactDOM from 'react-dom';
import './common/styles/main.scss';
import App from './app/App';
import {Provider} from "react-redux";
import {store} from "./app/store";
import {BrowserRouter, Routes, Route} from "react-router-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://f4b4186133c344bab7b3f6e46a68b067@o425936.ingest.sentry.io/6021010",
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App/>}/>
                        <Route path="/:slug" element={<App/>}/>
                    </Routes>
                </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

