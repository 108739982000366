import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ApiDossierUpdate} from "../common/api/Dossier/update";
import {RootInterface} from "../common/interfaces/RootInterface";
import {ApiGetRegistrationLink} from "../common/api/RegistrationLink/Get";
import moment from 'moment';
import {updateArgs} from "../common/interfaces/DossierInterface";
import {ApiDossierGet} from "../common/api/Dossier/get";
import {ApiDossierDelete} from "../common/api/Dossier/delete";
import {ApiDossierCreate} from "../common/api/Dossier/create";
import {ApiDossierCreateAndUpdate} from "../common/api/Dossier/createAndUpdate";
import {formatCreateAndUpdateData} from "../common/helpers/formatCreateAndUpdateData";
import {ApiCaravanSendExplanationEmail} from "../common/api/Dossier/caravanSendExplanationEmail";

export const caravanSendExplanationEmail = createAsyncThunk(
    'root/caravanSendExplanationEmail',
    async (args: { dossierUuid: number, dossierAccessToken: string, explanationCaravanDamage: string, establishmentId: number }, { getState, dispatch}) => {
        const { root } = getState() as { root: RootInterface };
        const response: any = await ApiCaravanSendExplanationEmail(args.dossierUuid, args.dossierAccessToken, root.dossier?.dossier_id, args.explanationCaravanDamage, args.establishmentId, dispatch);
        return response.data.status.message;
    }
);

export const getRegistrationLink = createAsyncThunk(
    'root/getRegistrationLink',
    async (slug: string, {dispatch}) => {
        const response = await ApiGetRegistrationLink(slug, dispatch)
        return {
            ...response.data.status.message,
            slug: slug
        }
    }
)

export const getDossier = createAsyncThunk(
    'root/getDossier',
    async (args: { dossierUuid: string, dossierAccessToken: string }, {dispatch}) => {
        const response: any = await ApiDossierGet(args.dossierUuid, args.dossierAccessToken, dispatch)

        if (response.data?.status?.message) {
            return response.data.status.message
        }

        return response
    }
)

export const deleteDossier = createAsyncThunk(
    'root/deleteDossier',
    async (args: { dossierUuid: string ,fundingType: string}, {dispatch}) => {
        const response: any = await ApiDossierDelete(args.dossierUuid, args.fundingType, dispatch)

        if (response.data?.status?.message) {
            return response.data.status.message
        }

        return response
    }
)

export const updateDossier = createAsyncThunk(
    'root/updateDossier',
    async (args: updateArgs, {getState, dispatch}) => {
        const {root} = getState() as { root: RootInterface };

        if (root.dossier?.dossier_id && root.dossier?.dossier_access_token) {
            const response: any = await ApiDossierUpdate(root.dossier.dossier_id, root.dossier.dossier_access_token, dispatch, args)
            return response.data.status.message
        }

        if (root.dossier?.car_license_plate || root.dossier?.person?.email) {
            let createAndUpdateArgs: any = formatCreateAndUpdateData(root.dossier)

            const replaceIndex = createAndUpdateArgs.data.findIndex((obj: {
                method: string | undefined;
            }) => obj.method === args.method);

            if (replaceIndex === -1) {
                createAndUpdateArgs.data.push(args)
            } else {
                createAndUpdateArgs.data[replaceIndex] = args
            }

            const response: any = await ApiDossierCreateAndUpdate(root.registrationLink.slug, dispatch, createAndUpdateArgs)
            return response.data.status.message
        }

        const response: any = await ApiDossierCreate(root.registrationLink.slug, dispatch, args)
        return response.data.status.message
    }
)

const initialState: RootInterface = {
    finished: false,
    enableResume: false,
    modals: {
        help: false,
        greenCard: false,
        smartPhoto: false,
        mobileNumberCheck: false
    },
    termsAccepted: 0,
    bovagAccepted: 0,
    privacyAccepted: 0,
    dossierLoading: false,
    dossierIsDeleted: false,
    dossierCreating: false,
    dossierError: {
        show: false,
        message: ''
    },
    registrationLink: {
        button_color_dark: '',
        button_color_light: '',
        primary_color: '',
        background_color: '',
        secondary_color: '',
        logo_url: '',
        notFound: false,
        loading: false,
        privacy_agreement_url: '',
        terms_and_condition_url: '',
        thank_you_page_url: '',
        slug: '',
        no_lease: false,
        private_lease: false,
        business_lease: false,
        private_insurance: false
    },
    overview: {
        show: false
    },
    getParams: {},
    expiresAt: moment().add(1, 'day').toISOString()
}


export const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        setFinished: (state, action: PayloadAction<boolean>) => {
            state.finished = action.payload
        },
        disableModals: (state) => {
            state.modals.help = false
            state.modals.greenCard = false
            state.modals.smartPhoto = false
            state.modals.mobileNumberCheck = false
        },
        enableHelpModal: (state) => {
            state.modals.help = true
            state.modals.greenCard = false
            state.modals.smartPhoto = false
            state.modals.mobileNumberCheck = false
        },
        enableGreenCardModal: (state) => {
            state.modals.greenCard = true
            state.modals.help = false
            state.modals.smartPhoto = false
            state.modals.mobileNumberCheck = false
        },
        enableSmartPhotoModal: (state) => {
            state.modals.greenCard = false
            state.modals.help = false
            state.modals.smartPhoto = true
            state.modals.mobileNumberCheck = false
        },
        enableMobileNumberCheckModal: (state) => {
            state.modals.greenCard = false
            state.modals.help = false
            state.modals.smartPhoto = false
            state.modals.mobileNumberCheck = true
        },
        toggleTerms: (state) => {
            state.termsAccepted = state.termsAccepted === 1 ? 0 : 1
        },
        toggleBovagTerms: (state) => {
            state.bovagAccepted = state.bovagAccepted === 1 ? 0 : 1
        },
        togglePrivacy: (state) => {
            state.privacyAccepted = state.privacyAccepted === 1 ? 0 : 1
        },
        toggleOverview: (state) => {
            state.overview.show = !state.overview.show
        },
        setGetParams: (state, action: PayloadAction<RootInterface['getParams']>) => {
            state.getParams = action.payload
        },
        deleteDossierId: (state) => {
            delete state.dossier?.dossier_id
            delete state.dossier?.dossier_access_token
        },
        disableApiError: (state) => {
            state.dossierError.show = false
            state.dossierError.message = ''
        },
        setDossierLoading: (state, action: PayloadAction<boolean>) => {
            state.dossierLoading = action.payload
        },
        unsetDossier: (state) => {
            delete state.dossier
        },
        setDossierApiError: (state, action: PayloadAction<number>) => {
            state.dossierError.show = true
            state.dossierLoading = false
            state.dossierError.message = 'Sorry, er is iets foutgegaan. Code: ' + action.payload
            if (action.payload === 401) {
                localStorage.clear();
                window.location.reload();
            }
        },
        setRegistrationLinkError: (state) => {
            state.registrationLink.loading = false
            state.registrationLink.notFound = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDossier.pending, (state) => {
            state.dossierLoading = true
        })
        builder.addCase(getDossier.fulfilled, (state, action) => {
            if (action.payload) {
                state.dossier = action.payload
            }
            state.dossierLoading = false
        })
        builder.addCase(updateDossier.pending, (state) => {
            state.dossierLoading = true
        })
        builder.addCase(updateDossier.fulfilled, (state, action) => {
            state.dossier = action.payload
            state.dossierIsDeleted = false
            state.dossierLoading = false
        })
        builder.addCase(deleteDossier.pending, (state) => {
            state.dossierLoading = true
        })
        builder.addCase(deleteDossier.fulfilled, (state) => {
            state.dossierIsDeleted = true
            state.dossierLoading = false
        })
        builder.addCase(getRegistrationLink.pending, (state) => {
            state.registrationLink.loading = true
        })
        builder.addCase(getRegistrationLink.fulfilled, (state, action) => {
            state.registrationLink = {
                ...state.registrationLink,
                ...action.payload,
                notFound: false,
                loading: false
            }
        })
    },
})

export const {
    setFinished,
    setDossierLoading,
    enableHelpModal,
    enableGreenCardModal,
    enableSmartPhotoModal,
    enableMobileNumberCheckModal,
    disableModals,
    toggleTerms,
    toggleBovagTerms,
    togglePrivacy,
    unsetDossier,
    deleteDossierId,
    setDossierApiError,
    setRegistrationLinkError,
    setGetParams,
    disableApiError
} = rootSlice.actions

export default rootSlice.reducer
