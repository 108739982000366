import {setFundingType, setLicense} from "../vehicleInformationSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {KentekenCheck} from 'rdw-kenteken-check'
import {useEffect, useRef, useState} from "react";
import {updateDossier} from "../../../app/rootReducer";
import {PatchStepsEnum} from "../../../common/constants/PatchStepsEnum";
import {deleteHelpType, setGeneralStep} from "../../helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../../common/constants/GeneralStepsEnum";
import {fundingTypeEnum} from "../../../types/fundingTypeEnum";

const regexWithLT = [
    '^([BDFGHJKLMNPRSTVWXYZ]{3})([0-9]{2})([BDFGHJKLMNPRSTVWXYZ]{1})$',// XXX99X 11
    '^([0-9]{1})([BDFGHJKLMNPRSTVWXYZ]{2})([0-9]{3})$',//9XX999 13
    '^([0-9]{3})([BDFGHJKLMNPRSTVWXYZ]{2})([0-9]{1})$',//999XX9 14
    '^([BDFGHJKLMNPRSTVWXYZ]{1})([0-9]{2})([BDFGHJKLMNPRSTVWXYZ]{3})$',//X99XXX  15
];

const VehicleInformationLicenseChecker = () => {

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth'})
        window.dataLayer.push({
            event: 'AanmeldenGegevens',
        });
    }, []);

    const dispatch = useAppDispatch()
    const {license} = useAppSelector((state) => state.vehicleInformation)
    const {dossier} = useAppSelector((state) => state.root)
    const {activeHelpType} = useAppSelector((state) => state.helpType)

    const [invalidLicense, setInvalidLicense] = useState(false);

    useEffect(() => {
        if (!!license) {
            const delayDebounceFn = setTimeout(() => {

                const licenseCheck = new KentekenCheck(license + '')
                licenseCheck.arrRegEx.push(...regexWithLT);

                const formattedLicense = licenseCheck.formatLicense()

                if (formattedLicense !== 'XX-XX-XX') {
                    setInvalidLicense(false)
                } else {
                    setInvalidLicense(true)
                }

            }, 800)

            return () => clearTimeout(delayDebounceFn)

        }

    }, [license])


    return (
        <div className="s-appointment__group border-color-primary" ref={scrollRef} id="license-check">
            <h1 className="s-appointment__group__title">Voer uw kenteken in</h1>

            <div className="c-license-checker">
                <div className="c-license-checker-nl">
                    <img src="/images/stars.svg" alt=""/> <span>NL</span>
                </div>

                <input type="text" className={'font-face-primary color-primary'} value={license}
                       onChange={(e) => onChange(e.target.value)}/>
            </div>

            {(invalidLicense) &&
                <div className="c-message background-light" style={{marginBottom: '1rem'}}>
                    <i className={'icon-info'}/>
                    <span>Dit is een onjuist kenteken.</span>
                </div>
            }

            {(dossier && dossier.car_license_plate && !dossier.car_model && !dossier.car_brand) &&
                <div className="c-message background-light">
                    <i className={'icon-info'}/>
                    <span>Uw kenteken wordt niet herkend. Vul hieronder handmatig het merk en model van uw voertuig in.</span>
                </div>
            }

            {dossier && dossier.car_license_plate && dossier.car_model && dossier.car_brand &&
                <div className="c-message background-light">
                    <i className="icon-car-front"/>
                    <span style={{textTransform: 'lowercase'}}>{dossier.car_brand} {dossier.car_model}</span>
                </div>
            }

        </div>
    )

    function onChange(value: string) {

        const replacedValue = value.replace(/\s/g, '')

        const license = new KentekenCheck(replacedValue)
        license.arrRegEx.push(...regexWithLT);
        const formattedLicense = license.formatLicense()

        setInvalidLicense(false)
        dispatch(setLicense(replacedValue))

        if (formattedLicense !== 'XX-XX-XX') {
            dispatch(setLicense(formattedLicense))

            if (!activeHelpType) {
                dispatch(setGeneralStep(GeneralStepsEnum.LicenseChecker))
                dispatch(deleteHelpType())
                dispatch(setFundingType(fundingTypeEnum.Unknown))
            }

            dispatch(updateDossier({
                data: {car_license_plate: formattedLicense},
                method: PatchStepsEnum.patchCarLicensePlate
            })).then(() => {
                if (!activeHelpType) {
                    dispatch(setGeneralStep(GeneralStepsEnum.TypeSelect))
                    return
                }


            })
        }
    }
}

export default VehicleInformationLicenseChecker
