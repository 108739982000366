export const SvgGroteSchade = () => {
    return (
        <svg className="large-damage" width="44px" height="34px" viewBox="0 0 44 34"
             version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="211118-ABS-platform-[ruitschade-2]" transform="translate(-917.000000, -1076.000000)"
                   fillRule="nonzero">
                    <g id="grote-schade" transform="translate(917.000000, 1076.000000)">
                        <path
                            d="M5.93153724,33.2748019 C2.66085578,33.2748019 0,30.6139461 0,27.3432646 L0,5.93153724 C0,2.66085579 2.66085578,0 5.93153724,0 L37.6693608,0 C40.9400422,0 43.600898,2.66085579 43.600898,5.93153724 L43.600898,27.3432646 C43.600898,30.6139461 40.9400422,33.2748019 37.6693608,33.2748019 L5.93153724,33.2748019 Z"
                            id="Fill-2" fill="#E67A01" className="fill-primary"></path>
                        <polygon id="Fill-1" fill="#E67A01" className="fill-secondary"
                                 points="37.4645529 13.8921202 37.2452676 14.0827862 33.076057 17.7065153 25.8322557 15.2415247 25.5889976 15.1588642 25.1511661 15.2375491 21.3167533 15.92716 22.5924006 12.1920296 22.9182497 11.2383687 22.184535 10.9127789 19.2303008 9.60081192 22.2777989 5.99431062 17.8966919 9.39573425 17.3339064 9.83272529 18.008346 10.3221453 20.397578 12.0562775 18.2053004 15.8634667 9.31509475 10.1181444 8.94852472 9.88117862 8.66298597 9.99481612 0.999989435 13.0518484 8.6498502 11.4905745 16.13732 18.4808575 13.2660874 22.0244937 12.753382 22.6571202 12.9980357 23.056094 15.1123196 26.5048943 14.9382707 23.2615858 18.225825 20.8842254 18.96307 22.4216453 19.2610056 23.0428418 19.6109096 23.1889473 26.5469235 26.0867048 27.0627487 30.8421711 27.6210189 25.6963449 27.6499175 25.4276567 27.3310468 25.2026179 21.5215071 21.1008159 21.134905 19.454728 25.5723316 18.0031988 33.048554 19.4390738 33.5619162 19.5377198 33.8331698 19.2271216 37.6633135 14.843959 43.0696672 16.506695 37.6767776 13.9911804"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}