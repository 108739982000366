import {ApiEnum} from "../../constants/ApiEnum";
import axios from "axios";
import {updateArgs} from "../../interfaces/DossierInterface";
import {disableApiError, setDossierApiError} from "../../../app/rootReducer";

export const ApiDossierUpdate = async (dossierId: number, dossierAccessToken: string, dispatch: any, args?: updateArgs) => {

    return await axios.patch(ApiEnum.DOSSIER + '/update', {
        dossierId: dossierId,
        dossierAccessToken: dossierAccessToken,
        data: args?.data,
        method: args?.method,
    }).then(function (response) {
        dispatch(disableApiError())
        return response
    }).catch(function (error) {
        dispatch(setDossierApiError(error.response.status))
        return error
    })

}
