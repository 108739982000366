import {ApiEnum} from "../../../constants/ApiEnum";
import axios from "axios";
import {setRegistrationLinkError} from "../../../../app/rootReducer";

export const ApiGetRegistrationLink = async (slug: string, dispatch: any) => {

    return await axios.get(ApiEnum.REGISTRATION_LINK + '/get', {
        params: {
            link: slug,
            base: window.location.origin
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        dispatch(setRegistrationLinkError())
        return error
    })
}
