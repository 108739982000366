import {ApiEnum} from "../../constants/ApiEnum";
import axios from "axios";
import {updateArgs} from "../../interfaces/DossierInterface";
import {disableApiError} from "../../../app/rootReducer";

export const ApiDossierCreate = async (slug: string, dispatch: any, args?: updateArgs) => {

    return await axios.post(ApiEnum.DOSSIER + '/create', {
        data: {
            ...args?.data,
            registration_link: slug
        }
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then(function (response) {
        dispatch(disableApiError())
        return response
    }).catch(function (error) {
        return error
    })

}
