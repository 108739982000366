import FormInput from "../../../common/components/form/FormInput";
import {setBrand, setBuildYear, setFundingType, setModel} from "../vehicleInformationSlice";
import FormSelectNumber from "../../../common/components/form/FormSelectNumber";
import {useEffect, useRef} from "react";
import {updateDossier} from "../../../app/rootReducer";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import VehicleInformationState from "../../../common/interfaces/VehicleInformationState";
import {PatchStepsEnum} from "../../../common/constants/PatchStepsEnum";
import {deleteHelpType, setGeneralStep} from "../../helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../../common/constants/GeneralStepsEnum";
import {fundingTypeEnum} from "../../../types/fundingTypeEnum";

const VehicleInformationBrandModel = () => {

    const {vehicleInformation} = useAppSelector((state) => state)
    const {brand, model, buildYear} = vehicleInformation
    const {dossier} = useAppSelector((state) => state.root)
    const dispatch = useAppDispatch()
    const buildYears = [];
    const renderCondition = dossier && dossier.car_license_plate && dossier.dossier_status && dossier.dossier_status.sequence > 0 && !dossier.car_audaid_available
    const ref = useRef<VehicleInformationState>(vehicleInformation);
    const isInitialMountBrandModel = useRef(true);
    const initialAmountBuildYear = useRef(true);
    const scrollRef = useRef<HTMLDivElement>(null);

    for (let i = 1920; i <= new Date().getFullYear(); i++) {
        buildYears.push(i);
    }

    useEffect(() => {
        scrollRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth' })
        window.dataLayer.push({
            event: 'AanmeldenGegevens'
        });
    }, []);

    useEffect(() => {

            if (!isInitialMountBrandModel.current) {

                if (ref.current && brand !== ref.current.brand && model !== ref.current.model) {

                    const timeout = setTimeout(() => {
                        dispatch(setGeneralStep(GeneralStepsEnum.LicenseChecker))
                        dispatch(deleteHelpType())
                        dispatch(setFundingType(fundingTypeEnum.Unknown))

                        dispatch(updateDossier(
                            {
                                data: {
                                    car_brand: brand,
                                    car_model: model
                                },
                                method: PatchStepsEnum.PatchModel
                            }
                        )).then(() => {
                            dispatch(setGeneralStep(GeneralStepsEnum.TypeSelect))
                        })

                    }, 600);
                    return () => {
                        clearTimeout(timeout);
                    }
                }
            } else {
                isInitialMountBrandModel.current = false
            }

        }, [brand, model]
    );


    useEffect(() => {

            if (!initialAmountBuildYear.current) {
                if (buildYear) {
                    dispatch(updateDossier({
                        data: {car_build_year: buildYear},
                        method: PatchStepsEnum.PatchYear
                    }))
                }

            } else {
                initialAmountBuildYear.current = false
            }

        }, [buildYear]
    );



    if (renderCondition) {
        return (
            <div className="s-appointment__group border-color-primary" ref={scrollRef}>

                <div className="c-form__row--half">
                    <FormInput
                        type={'text'}
                        onChange={(value) => dispatch(setBrand(value.toString()))}
                        value={brand} placeholder={'Vul merk in'}
                        label={'Merk'}
                        required={false}
                    />

                    <FormInput
                        type={'text'}
                        onChange={(value) => dispatch(setModel(value.toString()))}
                        value={model} placeholder={'Vul model in'}
                        label={'Model'}
                        required={false}
                    />
                </div>

                <div className="c-form__row">
                    <FormSelectNumber label={'Bouwjaar'}
                                      optional={true}
                                      value={buildYear}
                                      defaultValue={'Selecteer bouwjaar'}
                                      options={buildYears}
                                      onChange={(buildYear: string) => dispatch(setBuildYear(parseInt(buildYear)))}
                    />

                </div>

            </div>
        )
    } else return null
}

export default VehicleInformationBrandModel
