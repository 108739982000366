import ModalWrapper from "./ModalWrapper";

const HelpModal = () => {

    return (
        <ModalWrapper>
            <h1>Hulp nodig bij het aanmeldformulier?</h1>
            <p>Kom je niet verder in het formulier of wil je graag hulp bij het maken van een schadeherstel afspraak? Neem contact op met onze klantenservice. Wij helpen je telefonisch graag verder.</p>
            <p>Neem contact op met onze servicedesk via <a style={{'color': 'blue', textDecoration: 'underline'}}
                                                           href={'tel:0900-6611111'}>0900 - 66 11 11 1</a>.</p>
        </ModalWrapper>
    )
}

export default HelpModal
