import React from "react";
import {useAppDispatch} from "../../../app/hooks";
import {disableModals} from "../../../app/rootReducer";

interface Props {
    children?: React.ReactNode;
}

const ModalWrapper = (props: Props) => {

    const dispatch = useAppDispatch();

    return (
        <div className="c-info-popup c-info-popup--active">
            <div className="c-info-popup__bg"></div>
            <div className="c-info-popup__content">
                <button className="c-info-popup__close-btn c-info-popup__close"
                        onClick={() => dispatch(disableModals())} type="button">
                    <img src="/images/cross-black.svg"/>
                </button>
                {props.children}
            </div>
        </div>
    )

}

export default ModalWrapper
