import ModalWrapper from "./ModalWrapper";
import {useAppDispatch} from "../../../app/hooks";
import {enableHelpModal} from "../../../app/rootReducer";

const GreenCardModal = () => {

    const dispatch = useAppDispatch();

    return (
        <ModalWrapper>
            <img src="https://www.goedkopeautoverzekering.nl/wp-content/uploads/2017/02/voorbeeld-groene-kaart.png" style={{marginBottom: "1rem"}} />
            <h3>Wat hebben wij nodig van jouw groene kaart?</h3>
            <p>
                Je vindt het nummer op de groene kaart bij '4. Landcode/Code verzekeraar/nummer'. Het groene kaart nummer bestaat uit het polisnummer van je verzekering, de code van je verzekeraar en de code van het land waarin je woont.
            </p>
            <div className="c-modal__buttons">
                <a onClick={() => dispatch(enableHelpModal())}  className="btn btn--gray"><span>Help mij verder</span></a>
            </div>
        </ModalWrapper>
    )
}

export default GreenCardModal
