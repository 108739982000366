export const SvgGroterDan = () => {
    return (
        <svg className="medium-damage" width="39px" height="39px" viewBox="0 0 39 39"
             version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <polygon id="path-n28yp2fra5-1"
                         points="-1.5959456e-16 1.96891115e-16 27.1631472 1.96891115e-16 27.1631472 27.163063 -1.5959456e-16 27.163063"></polygon>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="211118-ABS-platform-[ruitschade-2]" transform="translate(-680.000000, -1073.000000)">
                    <g id="groter-dan" transform="translate(680.000000, 1073.000000)">
                        <polygon id="Fill-1" fill="#E67A01" className="fill-secondary" fillRule="nonzero"
                                 points="2.23014548 38.8962646 4.10000344 38.8962646 4.10000344 0.379167239 2.23014548 0.379167239"></polygon>
                        <polygon id="Fill-2" fill="#E67A01" className="fill-secondary" fillRule="nonzero"
                                 points="0 1.85784651 6.32728637 1.85784651 6.32728637 0 0 0"></polygon>
                        <polygon id="Fill-3" fill="#E67A01" className="fill-secondary" fillRule="nonzero"
                                 points="0 20.5664708 6.32868958 20.5664708 6.32868958 18.7089049 0 18.7089049"></polygon>
                        <polygon id="Fill-4" fill="#E67A01" className="fill-secondary" fillRule="nonzero"
                                 points="0 38.8575659 6.32728637 38.8575659 6.32728637 37 0 37"></polygon>
                        <g id="Group-10" transform="translate(10.145835, 6.056161)">
                            <g id="Fill-5-Clipped">
                                <mask id="mask-n28yp2fra5-2" fill="white">
                                    <use href="#path-n28yp2fra5-1"></use>
                                </mask>
                                <g id="path-1"></g>
                                <path
                                    d="M13.5815876,1.96891115e-16 C6.08071477,1.96891115e-16 -1.5959456e-16,6.08065864 -1.5959456e-16,13.5815315 C-1.5959456e-16,21.0824043 6.08071477,27.163063 13.5815876,27.163063 C21.0824605,27.163063 27.1631753,21.0824043 27.1631753,13.5815315 C27.1631753,6.08065864 21.0824605,1.96891115e-16 13.5815876,1.96891115e-16 M13.5815876,2.75028637 C16.4747205,2.75028637 19.1946976,3.8769496 21.2404055,5.92271363 C23.2861695,7.96842153 24.4128889,10.6883986 24.4128889,13.5815315 C24.4128889,16.4746644 23.2861695,19.1946414 21.2404055,21.2404055 C19.1946976,23.2861695 16.4747205,24.4127766 13.5815876,24.4127766 C10.6884547,24.4127766 7.96847766,23.2861695 5.92276975,21.2404055 C3.87700572,19.1946414 2.75028636,16.4746644 2.75028636,13.5815315 C2.75028636,10.6883986 3.87700572,7.96842153 5.92276975,5.92271363 C7.96847766,3.8769496 10.6884547,2.75028637 13.5815876,2.75028637"
                                    id="Fill-5" fill="#E67A01" className="fill-primary" fillRule="nonzero"
                                    mask="url(#mask-n28yp2fra5-2)"></path>
                            </g>
                            <path
                                d="M15.8937029,16.2208017 C15.4084738,16.7407181 14.8539824,17.052623 14.1261668,17.052623 C13.1671588,17.052623 12.4770053,16.4993664 12.1292905,15.458355 L15.4778483,15.458355 L15.4778483,14.0026115 L11.8821015,14.0026115 C11.8764325,13.8789609 11.8732332,13.752111 11.8732332,13.6213882 C11.8732332,13.4908899 11.8760958,13.3639277 11.8814841,13.2401648 L16.1709766,13.2401648 L16.1709766,11.7844775 L12.1156513,11.7844775 C12.4368174,10.779332 13.075389,10.2248406 14.0221049,10.2248406 C14.7152893,10.2248406 15.2352619,10.5021144 15.755066,10.8833378 L17.3840774,9.28901363 C16.4828816,8.38793001 15.3045242,7.93733207 14.1261668,7.93733207 C11.6572515,7.93733207 9.75635463,9.32280286 9.12154364,11.7992393 L7.99151271,11.8884271 L7.99151271,13.2401648 L8.90589874,13.2401648 C8.89719885,13.3987834 8.89265246,13.5604329 8.89265246,13.7253939 C8.89265246,13.8301293 8.89461695,13.933237 8.89798465,14.0350537 L7.99151271,14.1066173 L7.99151271,15.458355 L9.07304879,15.458355 C9.65150698,18.036103 11.5628999,19.3401316 13.9528426,19.3401316 C15.3391553,19.3401316 16.5522561,18.7855841 17.5226582,17.7458074 L15.8937029,16.2208017 Z"
                                id="Fill-8" fill="#E67A01" className="fill-primary" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}