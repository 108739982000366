import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {enableGreenCardModal} from '../../../app/rootReducer';
import {css} from '@emotion/react';
import MoonLoader from 'react-spinners/MoonLoader';

const override = css`
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 1.3rem;
`;

interface InputProps {
  label?: string;
  name?: string;
  required?: boolean;
  onChange: (value: string | number) => void;
  placeholder?: string;
  value?: string | number;
  modal?: string;
  type: string;
  loading?: boolean;
  pattern?: string;
  message?: string;
  maxLength?: number;
  prepend?: string;
  maxDate?: string;
  minDate?: string;
  error?: string | false
}

const FormInput = (props: InputProps) => {
  const dispatch = useAppDispatch();
  const {root} = useAppSelector((state) => state);
  const {registrationLink} = root;

  const modalButtonClick = () => {
    if (props.modal) {
      switch (props.modal) {
        case 'greenCard':
          dispatch(enableGreenCardModal());
          break;
      }
    }
  };

  return (
    <div className="c-form__group" style={{position: 'relative'}}>
      <label htmlFor="">
        {props.label} {props.required && <span>*</span>}{' '}
        {props.modal && <button onClick={() => modalButtonClick()} type="button">Waar vind ik dit?</button>}
      </label>

      {props.loading && (
        <MoonLoader
          color={registrationLink.secondary_color}
          loading={props.loading}
          css={override}
          size={15}
        />
      )}

      <div className={'c-input__group'}>
        {props.prepend && (
          <div className={'c-input__group--prepend'}>
            <span className={'c-input__group--text'}>{props.prepend}</span>
          </div>
        )}

        <input
          className={'border-color-primary'}
          disabled={props.loading}
          name={props.name}
          required={props.required}
          type={props.type}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          placeholder={props.placeholder}
          pattern={props.pattern}
          title={props.message}
          maxLength={props.maxLength}
          max={props.maxDate}
          min={props.minDate}
        />

        {!!props.error &&
            <p className="c-form__error">{props.error}</p>
        }


      </div>
    </div>
  );
};

export default FormInput;
