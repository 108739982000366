import Select from "react-select";

interface Option {
    label: string,
    value: number | string
}

interface SelectProps {
    onChange: (value: string) => void;
    value?: number | string,
    options: Option[]
    isClearable?: boolean
}


const FormSelectString = (props: SelectProps) => {

    const activeOption = props.options.find((option) => {
        return option.value === props.value
    }) as Option

    const styles = {
        control: (base: any) => ({
            ...base,
            fontSize: '1.5rem',
            borderColor: '#D1D1D1'
        }),
        menu: (base: any) => ({
            ...base,
            fontSize: '1.5rem'
        })
    };

    const customFilterOption = (option: any, inputValue: string) => {
        if (inputValue.length === 0) {
            return true;
        }
        if (inputValue.length < 3) {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        return option.label.toLowerCase().substring(0, 3) === inputValue.toLowerCase().substring(0, 3);
    };

    return (
            <Select
                isClearable={props.isClearable}
                maxMenuHeight={190}
                isMulti={false}
                menuPlacement={'top'}
                value={activeOption}
                className={'font-face-primary'}
                onChange={(option) => props.onChange(option?.value + '')}
                options={props.options.sort((a, b) => a.label > b.label ? 1 : -1)}
                styles={styles}
                filterOption={customFilterOption}
            />
    )
}

export default FormSelectString
