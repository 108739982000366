import {useAppSelector} from "../../../app/hooks";

const OverviewRepairReplace = () => {
    const {dossier} = useAppSelector((state) => state.root)

    if (dossier && dossier.appointment_type) {
        if (dossier.appointment_type.code === 'glass_replacement') {
            return (
                <div className="s-appointment__group border-color-primary">
                    <div className="s-appointment__group__header">
                        <h1 className="s-appointment__group__title">Vervangen</h1>
                    </div>
                    <p>Vanwege de grootte van de schade lijkt het erop dat we de ruit moeten vervangen. Dit duurt
                        ongeveer een halve dag.</p>
                </div>
            )
        }
        if (dossier.appointment_type.code === 'glass_repair') {
            return (
                <div className="s-appointment__group border-color-primary">
                    <div className="s-appointment__group__header">
                        <h1 className="s-appointment__group__title">Repareren</h1>
                    </div>
                    <p>Het lijkt erop dat we de ruit kunnen repareren. Dit duurt ongeveer 30 tot 45 minuten. Je kunt
                        hier op wachten.</p>
                </div>
            )
        }
    }
    return null
}

export default OverviewRepairReplace