import {useAppSelector} from "../../../app/hooks";
import {glassDamage} from "../../../common/interfaces/DossierInterface";
import {useEffect, useRef} from "react";
import {scrollToId} from "../../../common/helpers/scrollToId";
import {dossierTypeEnum} from "../../../types/dossierTypeEnum";


const OverviewVehicleInformation = () => {

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth' })
    }, [])

    const {activeHelpType} = useAppSelector((state) => state.helpType)
    const {dossier} = useAppSelector((state) => state.root)

    if (dossier) {
        return (
            <>
                <h1 className="s-appointment__group__title s-appointment__group__title--outside" ref={scrollRef}>Samenvatting</h1>

                <div className="s-appointment__group border-color-primary">

                    <div className="s-appointment__group__header">
                        <h1 className="s-appointment__group__title">Gegevens auto</h1>
                        <button className="btn--info" onClick={() => scrollToId('license-check')}>
                            <span><u>Wijzig</u></span>
                        </button>
                    </div>


                    {dossier.car_license_plate &&
                        <div className="c-license-checker">

                            <div className="c-license-checker-nl">
                                NL
                            </div>

                            <input
                                className="font-face-primary color-primary"
                                type="text"
                                disabled={true}
                                value={dossier.car_license_plate}
                            />

                            <div className="c-message background-light">
                                <i className="icon-car-front"/>
                                <span>{dossier.car_brand} {dossier.car_model}</span>
                            </div>

                        </div>
                    }


                    {(activeHelpType === dossierTypeEnum.GlassRepair || activeHelpType === dossierTypeEnum.GlassReplacement) &&
                        <>
                            <GlassDamageOveviewInfo/>
                        </>
                    }

                </div>

            </>

        )
    } else return null

}

const GlassDamageOveviewInfo = () => {

    const {dossier} = useAppSelector((state) => state.root)

    if (dossier && dossier.glass_damage_selection) {

        const damageLocation = dossier.glass_damage_selection.options.find((glassDamage: glassDamage) => glassDamage.damage_type.code === 'damage_location')
        const damageSize = dossier.glass_damage_selection.options.find((glassDamage: glassDamage) => glassDamage.damage_type.code === 'damage_size')
        const damageExactSize = dossier.glass_damage_selection.options.find((glassDamage: glassDamage) => glassDamage.damage_type.code === 'damage_exact_size')
        const damageExactLocation = dossier.glass_damage_selection.options.find((glassDamage: glassDamage) => glassDamage.damage_type.code === 'damage_exact_location')
        const damageCause = dossier.glass_damage_selection.options.find((glassDamage: glassDamage) => glassDamage.damage_type.code === 'damage_cause')

        return (
            <>

                <div className="s-appointment__group border-color-primary">
                    <div className="s-appointment__group__header">
                        <h1 className="s-appointment__group__title">Beschrijving schade</h1>
                        <button className="btn--info">
                            <span><u>Wijzig</u></span>
                        </button>
                    </div>
                    <p>Schade bij
                        de {damageLocation?.damage_option?.name} is {damageSize?.damage_option?.name} door {damageCause?.damage_option?.name}.</p>
                </div>


                {damageExactLocation && damageExactLocation.damage_option &&
                    <div>
                        <small className={'color-primary'}>Specifieke locatie</small>
                        <span className={'color-primary'}>{damageExactLocation.damage_option.name}</span>
                    </div>
                }

                {damageExactSize && damageExactSize.damage_option &&
                    <div>
                        <small className={'color-primary'}>Specifieke grootte</small>
                        <span className={'color-primary'}>{damageExactSize.damage_option.name}</span>

                    </div>
                }


            </>
        )
    } else return null

}

export default OverviewVehicleInformation
