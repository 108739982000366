import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    damageCauses,
    damageExactLocations,
    damageExactSizes,
    damageLocations,
    damageSizes,
} from "../../../common/constants/damageOptions";
import {
    setGlassDamageCause,
    setGlassDamageExactLocation,
    setGlassDamageExactSize,
    setGlassDamageLocation,
    setGlassDamageSize
} from "../vehicleInformationSlice";
import {updateDossier} from "../../../app/rootReducer";
import {SvgVoorruit} from "../../../common/components/svgIcons/SvgVoorruit";
import {SvgZijrruit} from "../../../common/components/svgIcons/SvgZijrruit";
import {SvgAchterruit} from "../../../common/components/svgIcons/SvgAchterruit";
import {SvgDakruit} from "../../../common/components/svgIcons/SvgDakruit";
import {SvgKleinerDan} from "../../../common/components/svgIcons/SvgKleinerDan";
import {SvgBiggerThan} from "../../../common/components/svgIcons/SvgBiggerThan";
import {SvgGroteSchade} from "../../../common/components/svgIcons/SvgGroteSchade";
import {SvgSmallerThan} from "../../../common/components/svgIcons/SvgSmallerThan";
import {SvgGroterDan} from "../../../common/components/svgIcons/SvgGroterDan";
import {SvgSteenslag} from "../../../common/components/svgIcons/SvgSteenslag";
import {SvgInbraak} from "../../../common/components/svgIcons/SvgInbraak";
import {SvgAanrijding} from "../../../common/components/svgIcons/SvgAanrijding";
import {SvgVandalisme} from "../../../common/components/svgIcons/SvgVandalisme";
import {SvgStorm} from "../../../common/components/svgIcons/SvgStorm";
import {PatchStepsEnum} from "../../../common/constants/PatchStepsEnum";
import {useEffect, useRef} from "react";
import {setGeneralStep, setGlassReplacement} from "../../helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../../common/constants/GeneralStepsEnum";
import {unsetAppointment} from "../../appointment/appointmentSlice";
import {dossierTypeEnum} from "../../../types/dossierTypeEnum";

const VehicleInformationGlassDamage = () => {

    const dispatch = useAppDispatch()

    const {activeHelpType} = useAppSelector((state) => state.helpType)
    const {glassDamage} = useAppSelector((state) => state.vehicleInformation)
    const {dossier} = useAppSelector((state) => state.root)

    const isInitialMount = useRef(true);
    const scrollRef = useRef<HTMLDivElement>(null);

    const renderCondition = !!dossier && (activeHelpType === dossierTypeEnum.GlassReplacement || activeHelpType === dossierTypeEnum.GlassRepair)

    useEffect(() => {
        scrollRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth'})

        window.dataLayer.push({
            event: 'RuitschadeAanmeldenUwschade'
        });

    }, []);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return
        }
        window.scrollTo(0, document.body.scrollHeight);
    }, [glassDamage.damageLocation, glassDamage.damageSize, glassDamage.damageExactLocation, glassDamage.damageExactSize])

    const repairPossibleCondition = glassDamage.damageSize === 5 && glassDamage.damageCause &&
        (glassDamage.damageExactLocation === 11 || (glassDamage.damageExactLocation === 10 && glassDamage.damageExactSize === 8))

    const replacementPossibleCondition = (
        (glassDamage.damageSize !== 5 && glassDamage.damageCause) || // Niet kleiner dan munt van 2CM (id 5) + oorzaak ingevuld
        ((glassDamage.damageExactLocation && glassDamage.damageExactLocation === 10) && (glassDamage.damageExactSize && glassDamage.damageExactSize === 9) && glassDamage.damageCause))

    const replacementTakesTimeCondition = glassDamage.damageLocation !== 1

    useEffect(() => {
        if (repairPossibleCondition) {
            dispatch(setGeneralStep(GeneralStepsEnum.Lease))
        }

        if (replacementPossibleCondition) {
            dispatch(setGeneralStep(GeneralStepsEnum.Lease))
        }

        if (!repairPossibleCondition && !replacementPossibleCondition && !replacementTakesTimeCondition) {
            dispatch(setGeneralStep(GeneralStepsEnum.GlassDamage))
        }

        if (glassDamage.damageLocation && replacementTakesTimeCondition) {
            dispatch(setGeneralStep(GeneralStepsEnum.Appointment))
            dispatch(setGlassReplacement(true))
        } else {
            dispatch(setGlassReplacement(false))
        }
    }, [glassDamage])

    if (renderCondition) {
        return (

            <>

                <div className="s-appointment__group border-color-primary" ref={scrollRef}>
                    <h1 className="s-appointment__group__title">Selecteer locatie van schade</h1>
                    {damageLocations &&
                        <div className="c-icon-button c-icon-button--row">
                            {damageLocations.map((damageLocation) => {
                                return (
                                    <GlassDamageButton
                                        svgPosition={'left'}
                                        active={damageLocation.value === glassDamage.damageLocation}
                                        onClick={(value) => {
                                            dispatch(unsetAppointment())
                                            dispatch(setGlassDamageLocation(value))
                                            dispatch(updateDossier({
                                                data: {glass_damage_location: value},
                                                method: PatchStepsEnum.PatchGlassDamageLocation
                                            }))
                                        }}
                                        key={'damage-location-' + damageLocation.value} {...damageLocation}
                                    />
                                )
                            })}
                        </div>
                    }
                </div>

                {glassDamage.damageLocation && glassDamage.damageLocation === 1 &&
                    <>
                        <div className="s-appointment__group border-color-primary">
                            <h1 className="s-appointment__group__title">Grootte van de schade</h1>
                            {damageSizes &&
                                <div className="c-form__buttons c-form__buttons--start">
                                    {damageSizes.map((damageSize) => {
                                        return (
                                            <GlassDamageButton
                                                active={damageSize.value === glassDamage.damageSize}
                                                onClick={(value) => {
                                                    dispatch(setGlassDamageSize(value))
                                                    dispatch(updateDossier({
                                                        data: {glass_damage_size: value},
                                                        method: PatchStepsEnum.PatchGlassDamageSize
                                                    }))
                                                }}
                                                key={'damage-size-' + damageSize.value} {...damageSize}/>
                                        )
                                    })}
                                </div>
                            }
                        </div>


                        {glassDamage.damageSize === 5 &&
                            <div className="s-appointment__group border-color-primary">
                                <h1 className="s-appointment__group__title">Specifieke locatie schade</h1>
                                {damageExactLocations &&
                                    <div className="c-form__buttons c-form__buttons--start">
                                        {damageExactLocations.map((damageExactLocation) => {
                                            return (
                                                <GlassDamageButton
                                                    active={damageExactLocation.value === glassDamage.damageExactLocation}
                                                    onClick={(value) => {
                                                        dispatch(setGlassDamageExactLocation(value))
                                                        dispatch(updateDossier({
                                                            data: {glass_damage_exact_location: value},
                                                            method: PatchStepsEnum.PatchGlassDamageExactLocation
                                                        }))
                                                    }
                                                    }
                                                    key={'damage-exact-location-' + damageExactLocation.value} {...damageExactLocation}/>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }

                        {glassDamage.damageExactLocation === 10 &&
                            <div className="s-appointment__group border-color-primary">
                                <h1 className="s-appointment__group__title">Afmeting van schade</h1>
                                {damageExactSizes &&
                                    <div className="c-form__buttons c-form__buttons--start">
                                        {damageExactSizes.map((damageExactSize) => {
                                            return (
                                                <GlassDamageButton
                                                    active={damageExactSize.value === glassDamage.damageExactSize}
                                                    onClick={(value) => {
                                                        dispatch(setGlassDamageExactSize(value))
                                                        dispatch(updateDossier({
                                                            data: {glass_damage_exact_size: value},
                                                            method: PatchStepsEnum.PatchGlassDamageExactSize
                                                        }))
                                                    }
                                                    }
                                                    key={'damage-exact-size-' + damageExactSize.value} {...damageExactSize}/>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }

                        {((!!glassDamage.damageExactSize || glassDamage.damageExactLocation === 11) || (glassDamage.damageSize && glassDamage.damageSize !== 5)) &&
                            <div className="s-appointment__group border-color-primary">
                                <h1 className="s-appointment__group__title">Selecteer oorzaak</h1>
                                {damageCauses &&
                                    <div className="c-form__buttons c-form__buttons--start">
                                        {damageCauses.map((damageCause) => {
                                            return (
                                                <GlassDamageButton
                                                    active={damageCause.value === glassDamage.damageCause}
                                                    onClick={(value) => {
                                                        dispatch(setGlassDamageCause(value))
                                                        dispatch(updateDossier({
                                                            data: {glass_damage_cause: value},
                                                            method: PatchStepsEnum.PatchGlassDamageCause
                                                        }))
                                                    }}
                                                    key={'damage-cause-' + damageCause.value} {...damageCause}
                                                />
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </>
                }
            </>
        )
    } else return null

}

interface ButtonProps {
    onClick: (value: number) => void,
    svgPosition?: string,
    active: boolean,
    img?: string,
    label: string,
    value: number
}

const GlassDamageButton = (props: ButtonProps) => {

    let className = 'c-icon-button__single border-color-primary border-color-primary-hover'

    if (props.active) {
        className += ' button-active'
    }

    return (
        <button className={className} onClick={() => props.onClick(props.value)}>
            {!!props.img &&
                <>
                    {{
                        'frontWindow': <SvgVoorruit/>,
                        'sideWindow': <SvgZijrruit/>,
                        'backWindow': <SvgAchterruit/>,
                        'roofWindow': <SvgDakruit/>,
                        'small': <SvgKleinerDan/>,
                        'medium': <SvgGroterDan/>,
                        'large': <SvgGroteSchade/>,
                        'smallerThanOneCm': <SvgSmallerThan/>,
                        'largerThanOneCm': <SvgBiggerThan/>,
                        'stone': <SvgSteenslag/>,
                        'burglary': <SvgInbraak/>,
                        'crash': <SvgAanrijding/>,
                        'vandalism': <SvgVandalisme/>,
                        'storm': <SvgStorm/>,
                    }[props.img]}
                </>
            }

            <span className={'font-face-primary'}>{props.label}</span>
        </button>
    )

}

export default VehicleInformationGlassDamage
