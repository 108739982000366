export const SvgSmallerThan = () => {
    return (
        <svg width="23px" height="21px" viewBox="0 0 23 21" version="1.1" xmlns="http://www.w3.org/2000/svg"
          >
            <title>Group 27</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-27">
                    <g id="Group-8"
                       transform="translate(11.000000, 17.347861) rotate(-90.000000) translate(-11.000000, -17.347861) translate(7.500000, 6.347861)"
                       className={'fill-secondary'}>
                        <polygon id="Fill-6"
                                 points="2.34786054 21.5 4.34786054 21.5 4.34786054 0.5 2.34786054 0.5"></polygon>
                        <polygon id="Fill-2"
                                 points="0.00137656232 2.21633299 6.33522073 2.21633299 6.33522073 0.356569201 0.00137656232 0.356569201"></polygon>
                        <polygon id="Fill-3" points="0 11.8594548 6.33522073 11.8594548 6.33522073 10 0 10"></polygon>
                        <polygon id="Fill-4" points="0 21.8594829 6.33384417 21.8594829 6.33384417 20 0 20"></polygon>
                    </g>
                    <polygon id="Fill-1" fill="#192F59" className="fill-hover fill-primary"
                             points="19.9960108 4.40149631 19.8757617 4.50775556 17.5894987 6.52728138 13.6172277 5.15352746 13.4838327 5.10746024 13.2437397 5.1513118 11.1410693 5.53563609 11.8405939 3.45402569 12.0192792 2.92254481 11.6169333 2.74109167 9.99692489 2.00992464 11.6680763 8.8817842e-16 9.26561608 1.89563363 8.95700231 2.13917134 9.3268436 2.41192803 10.6370227 3.37837022 9.43484717 5.50013941 4.55974018 2.29823709 4.3587248 2.16617466 4.2021444 2.22950555 -1.0658141e-13 3.93320783 4.19494116 3.06310059 8.30083228 6.95882688 6.72633935 8.93371643 6.44518793 9.28628296 6.57934826 9.50863344 7.73875456 11.4306703 7.64331164 9.62315525 9.44610223 8.29823811 9.850384 9.1550514 10.0137625 9.50124791 10.2056387 9.58267333 14.0091289 11.1976108 14.2919911 13.8478605 14.5981287 10.9800609 14.6139758 10.8303194 14.4391172 10.704904 11.2533498 8.41894529 11.0413495 7.50157069 13.4746936 6.69262484 17.5744169 7.49284654 17.8559285 7.54782255 18.0046754 7.37472429 20.1050048 4.93196169 23.0696778 5.85861436 20.1123881 4.45670312"></polygon>
                </g>
            </g>
        </svg>
    )
}