import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEnum } from "../../constants/ApiEnum";
import { disableApiError } from "../../../app/rootReducer";

export const postMissingContractorName = createAsyncThunk(
    'contractor/postMissingContractorName',
    async ({ name, uuid, accessToken, dossierId }: { name: string, uuid: string, accessToken: string, dossierId: number }, { dispatch }) => {
        try {
            const response = await axios.post(ApiEnum.EMAIL_ESTABLISHMENT, {
                name: name,
                registration_link: uuid,
                dossierAccessToken: accessToken,
                dossierId: dossierId
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            dispatch(disableApiError());
            // Remove non-serializable values before returning the response
            const { config, request, ...serializableResponse } = response;
            return serializableResponse;
        } catch (error) {
            return error;
        }
    }
);