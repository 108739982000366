import {useAppSelector} from "../../../app/hooks";
import {scrollToId} from "../../../common/helpers/scrollToId";


const OverviewForm = () => {

    const {dossier} = useAppSelector((state) => state.root)

    if (dossier && dossier.person) {
        return (

            <div className="s-appointment__group border-color-primary">
                <div className="s-appointment__group__header">
                    <h1 className="s-appointment__group__title">Jouw gegevens</h1>
                    <button className="btn--info" onClick={() => {
                        scrollToId('naw-form')
                    }}>
                        <span><u>Wijzig</u></span>
                    </button>
                </div>
                <p><b>Volledige naam</b></p>
                <p>{dossier.person.first_name} {dossier.person.insertion} {dossier.person.last_name}</p>
                <p><b>E-mailadres</b></p>
                <p>{dossier.person.email}</p>
                <p><b>Mobiel nummer</b></p>
                <p>{dossier.person.mobile_number}</p>
                <p><b>Groene kaart nummer</b></p>
                <p>{dossier.green_card_code}</p>
            </div>


        )
    }

    return null

}

export default OverviewForm
